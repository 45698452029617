<template>
  <div
    class="px-0 py-3 profile content-wrapper mt-md-3 mt-0"
    v-if="user && user.id"
  >
    <div class="d-md-flex">
      <b-card class="col-md-2 p-0 mr-3 mobile-transparent">
        <div class="profile-detail mx-auto">
          <div class="avatar-container text-center mx-auto">
            <figure class="avatar">
              <avatar width="150px" height="150px" :image="user.photo" />
            </figure>
            <b-icon
              icon="camera"
              size="lg"
              class="pointer h4 change-avatar"
              v-b-modal.upload-photo
            />
          </div>
          <div class="mt-4 text-center">
            <h5 class="card-title mb-0">{{ user.full_name }}</h5>
            <p class="text-muted mb-2">{{ user.profile.name }}</p>
          </div>
        </div>
      </b-card>
      <b-card
        :class="[
          'w-100  mobile-transparent p-4',
          is_mobile ? 'min-h-50' : 'min-h-80',
        ]"
      >
        <b-form>
          <b-tabs
            content-class="mt-0 mx-md-5 mt-4 mt-md-0"
            small
            pills
            :vertical="!is_mobile"
          >
            <b-tab title="Dados pessoais" active>
              <b-form-group label="Nome">
                <b-form-input
                  @input="handleChangeProfile"
                  v-model="user.full_name"
                />
              </b-form-group>
              <b-form-group label="E-mail">
                <b-form-input v-model="user.email" disabled />
              </b-form-group>
            </b-tab>

            <b-tab title="Segurança e senha">
              <b-form-group label="Senha atual">
                <b-form-input
                  type="password"
                  placeholder="****"
                  autocomplete="off"
                  v-model="password"
                  :class="invalidPassword && 'is-invalid'"
                />
                <b-form-invalid-feedback v-if="invalidPassword">
                  Senha atual não confere
                </b-form-invalid-feedback>
              </b-form-group>
              <!-- New  -->
              <b-form :form="form">
                <b-form-group label="Nova senha">
                  <b-form-input
                    type="password"
                    autocomplete="off"
                    placeholder="****"
                    v-model="$v.form.new_password.$model"
                    :class="$v.form.new_password.$error && 'is-invalid'"
                    trim
                  >
                  </b-form-input>
                  <b-form-invalid-feedback
                    v-if="!$v.form.new_password.required"
                  >
                    Esse campo é obrigatório
                  </b-form-invalid-feedback>
                  <b-form-invalid-feedback
                    v-if="!$v.form.new_password.minLength"
                  >
                    A senha deve conter no mínimo 6 caracteres
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group label="Repita a nova senha">
                  <b-form-input
                    type="password"
                    autocomplete="off"
                    placeholder="****"
                    v-model="$v.form.new_confirm_password.$model"
                    :class="$v.form.new_confirm_password.$error && 'is-invalid'"
                    trim
                  ></b-form-input>
                  <b-form-invalid-feedback
                    v-if="!$v.form.new_confirm_password.required"
                  >
                    Esse campo é obrigatório
                  </b-form-invalid-feedback>
                  <b-form-invalid-feedback
                    v-if="!$v.form.new_confirm_password.sameAsPassword"
                  >
                    Senhas não coincidem
                  </b-form-invalid-feedback>
                </b-form-group>
                <div class="d-flex justify-content-end">
                  <b-button
                    size="sm"
                    :disabled="loading"
                    @click="updatePassword()"
                    class="d-flex align-items-center"
                  >
                    <b-spinner v-if="loading" small class="mr-2" />
                    <span>Alterar senha</span>
                  </b-button>
                </div>
              </b-form>
            </b-tab>
          </b-tabs>
        </b-form>
      </b-card>
    </div>
    <b-modal
      id="upload-photo"
      size="md"
      hide-footer
      centered
      title="Alterar foto do perfil"
    >
      <file-upload @upload="handleUploadImage" image />
      <template #modal-footer="{ close }">
        <b-button variant="link" @click="close()">Cancelar</b-button>
        <b-button variant="primary" @click="handleDeleteProfile()">
          Salvar
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required, sameAs, minLength } from "vuelidate/lib/validators";
import FileUpload from "@/components/FileUpload";
import _ from "lodash";

export default {
  components: {
    FileUpload,
  },

  mixins: [validationMixin],
  data() {
    return {
      password: null,
      invalidPassword: false,
      loading: false,
      form: {
        new_password: null,
        new_confirm_password: null,
      },
    };
  },
  validations: {
    form: {
      new_password: {
        required,
        minLength: minLength(6),
      },
      new_confirm_password: {
        required,
        sameAsPassword: sameAs("new_password"),
      },
    },
  },

  methods: {
    ...mapActions([
      "get_user",
      "edit_user",
      "hide_application_header",
      "show_toast",
      "login",
    ]),
    handleUploadImage(file) {
      this.edit_user({ photo: file, id: this.user.id }).then(() => {
        this.$bvModal.hide("upload-photo");
        this.get_user();
      });
    },

    handleChangeProfile: _.debounce(function () {
      this.update();
    }, 1000),

    updatePassword() {
      this.$v.form.$touch();
      if (!this.$v.form.$anyError && !this.$v.$anyError) {
        this.invalidPassword = false;
        this.loading = true;
        const email = this.user.email;
        const password = this.password;

        this.login({ email, password })
          .then(({ status }) => {
            this.loading = false;
            if (status === 200) {
              this.update();
              this.invalidPassword = false;
            } else {
              this.invalidPassword = true;
              this.loading = false;
              this.show_toast({
                message: "Não foi possível atualizar o perfil",
                type: "error",
              });
            }
          })
          .catch(() => {
            this.invalidPassword = true;
            this.loading = false;
          });
      }
    },

    update() {
      this.edit_user({
        id: this.user.id,
        full_name: this.user.full_name,
        password: this.form.new_confirm_password,
      }).then(({ status }) => {
        if (status === 200) {
          this.show_toast({
            message: "Perfil atualizado",
            type: "success",
          });
        } else {
          this.show_toast({
            message: "Não foi possível atualizar o perfil",
            type: "error",
          });
        }
      });
    },
  },

  beforeDestroy() {
    this.hide_application_header(true);
  },
  mounted() {
    this.hide_application_header(false);
  },
  computed: {
    ...mapGetters(["user", "is_mobile"]),
  },
};
</script>
<style lang="scss">
.profile {
  max-width: $box;
  border-radius: 6px;
  .cover {
    img {
      object-fit: cover;
    }
  }
  .profile-detail {
    margin: 0 0;
  }
  .avatar-container {
    position: relative;
    width: 150px;
    height: 150px;
    .initials {
      height: 142px;
      width: 142px;
      font-size: 50px;
    }
    .avatar {
      border: 4px solid $bg;
      background: $bg-2;
      height: 150px;
      width: 150px;
      min-height: 100%;
      overflow: hidden;
      border-radius: 50%;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .change-avatar {
      position: absolute;
      background: $text-color;
      right: 24px;
      bottom: 0;
      width: 30px;
      height: 30px;
      padding: 5px;
      border-radius: 50px;
      color: $bg-2;
    }
  }
}
</style>